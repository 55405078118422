.nav-bottom {
  text-decoration: none;
  width: 100%;
  padding: 0px 40px;
}

.footer {
  width: 100%;
  height: auto;
  background-color: blue;
}
.footer hr {
  width: 90%;
  color: #000;
  height: 2px;
}

ul {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
ul p {
  color: #fff;
  margin-top: 12px;
  font-weight: 600;
}
ul li {
  margin-top: 14px;
  color: #fff;
}
ul li .cts-links {
  color: #fff;
  text-decoration: none;
  margin: 0px 8px;
}

.copyright-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.copyright-box P {
  color: white;
  font-size: medium;
}

.cta-image-1 {
  display: flex;
}
.cta-image-1 .logo-image-bottom {
  height: 60px;
  width: 120px;
  margin: 4px;
}
.cta-image-1 p {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .copyright-box {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 464px) {
  .copyright-box {
    padding: 4px;
    margin: 4px !important;
    font-size: 14px;
  }
}
.bottom-navbar {
  width: 100%;
  display: none;
}
.bottom-navbar .wrapper-bottom-navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}
.bottom-navbar .cta-link-bottom-bar a {
  color: #000;
}
.bottom-navbar .cta-link-bottom-bar a i {
  font-size: 22px;
}

@media screen and (max-width: 420px) {
    .footer {
        display: none;
        width: 100%;
        height: auto;
        background-color: blue;
    }
  .bottom-navbar {
    display: block;
    position: fixed;
    bottom: 0;
    background-color: #fff;
  }
}/*# sourceMappingURL=footer.css.map */