.cta-container{
  width: 80%;
 
    h3{

        margin: 20px;
    }

   

    .form-element{

        margin: 30px;

        .submit-btn {
            padding: 0%;
            background-color: blue;
            color: #fff;
            border-radius: 8px solid blue;
            border: 2px solid blue;
            font-weight: 400;
            font-size: 18px;
            margin-top: 30px;
            font-weight: 700;
        }
        .submit-btn:hover{
            color:blue;
            background-color: white;
        } 

        
        input{
            width: 450px;
            height: 40px;
            padding: 12px;
            border-radius: 8px grey;
        }
        textarea{
            width: 450px;
            height: 250px;
            padding: 12px;
            border-radius: 8px grey;
        }
    
    }
}
.cta-container{
  
    h5{
        margin: 20px;

        i{
            margin: 3px;
        }
    }
    p{
        padding: 20px;
        margin: 20px;
    }
    iframe
        {
            margin: 30px;
            height: 360px;
            width: 450px;
        }
}
.cta-wrapper-contact{
    display: flex !important;
    align-items: center;
    margin:  180px 0px 40px 0px !important;
    width: 100%;
   
   
}


@media screen and (max-width: 1073px) {
    
    .cta-wrapper-contact{
        display: flex;    
        flex-wrap: wrap;
        justify-content: center;

    }
  }

@media screen and(max-width:600px){

    .form-element{
        input{
            width: 300px !important;
            height: 40px !important;
            padding: 12px ;
            border-radius: 8px grey;
        }
        textarea{
            width: 300px !important;
            height: 180px !important;
            padding: 12px;
            border-radius: 8px grey;
        }
    }
    
    iframe
        {
            margin: 15px;
            height: 260px !important;
            width: 340px !important;
        }
        .cta-contact-location{
            margin-left: 0px !important;
        }

}
@media screen and (max-width:420px){

    .cta-container .form-element{
        text-align: center;
    }
    .submit-btn {
        margin-top: 0px !important;
    }
    .form-element{
        margin-left: 0px !important;
        input{
            font-size: 12px !important;
            padding: 4px;
        }
        textarea{
            font-size: 12px !important;
            padding: 4px;
        }
    }
    // .cta-wrapper-contact{
    //     margin: 200px  5px 100px 5px !important;
    // }
    .cta-container h3 {
        margin: 2px !important;
        font-size: 18px;
    }
    .cta-container h5{
        margin: 0px;
        font-size: 18px;
    }
    .form-element{ input{
        width: 240px !important;
        height: 40px !important;
    }
    textarea{
        width: 240px !important;
        height: 150px !important;}}

        iframe
        {
            margin: 5px;
            height: 230px !important;
            width: 270px !important;
        }
        .cta-container{
            p{
                margin: 0px !important;
                font-size: 12px;
            }
        }
}
@media screen and(max-width:390px) {
    
    iframe{
        height: 200px !important;
        width: 250px !important;
    }
}



.cta-contact-location{
    margin-left: 40px;
}