/* style.css */
.navigation-bar-main {
  position: fixed;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 999999;
}

.Main {
  margin-top: 180px;
  display: flex;
  /* Add this CSS to your 'style.css' file */
  /* Control buttons */
  /* Fullscreen mode */
}
.Main .wrapper-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 200px;
}
.Main .modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}
.Main .modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid rgb(227, 47, 47);
  text-align: center;
  position: relative;
}
.Main .modal img {
  max-width: 80%;
  max-height: 60vh;
  display: block;
  margin: 0 auto;
}
.Main .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.Main .close:hover {
  color: #f00;
}
.Main .image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.Main .image-container div {
  cursor: pointer;
}
.Main .image-container div img {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid black;
  border-radius: 10px;
}
.Main .control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  z-index: 2;
}
.Main .left {
  left: 10px;
}
.Main .right {
  right: 10px;
}
.Main .fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.Main .fullscreen-content {
  text-align: center;
  color: #fff;
  position: relative;
}
.Main .fullscreen img {
  max-width: 50%;
  max-height: 50%;
  margin-top: 200px;
}
.Main .close {
  position: absolute;
  top: 10px;
  right: 10px;
  margin-top: 160px;
  font-size: 30px;
  cursor: pointer;
}
.Main .sidenavbar {
  position: fixed;
  justify-content: start;
  align-items: flex-start;
  width: 180px;
  height: auto;
  background-color: #fefefe;
}
.Main .sidenavbar li {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  background-color: #fefefe;
  color: black;
  width: 95%;
  font-weight: 500;
}
.Main .sidenavbar li:hover {
  background-color: rgb(209, 206, 206);
}

@media screen and (max-width: 600px) {
  .sidenavbar {
    width: 140px !important;
  }
  .wrapper-gallery {
    width: 80%;
    margin-left: 155px !important;
  }
}
@media screen and (max-width: 832px) {
  .Main {
    margin-top: 210px;
  }
  .wrapper-gallery {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 420px) {
  .Main .right {
    display: none;
  }
  .Main .left {
    display: none;
  }
  .Main .fullscreen img {
    max-width: 70%;
    max-height: 70%;
    margin-top: 200px;
  }
  .sidenavbar {
    margin-top: -10px !important;
  }
  .fullscreen {
    display: none;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-top: 120px !important;
    font-size: 20px;
    cursor: pointer;
  }
  .sidenavbar {
    width: 100px !important;
    top: -188px !important;
  }
  .sidenavbar .brand-dropdown {
    display: block !important;
  }
  .sidenavbar li {
    margin: 0px !important;
    padding: 5px;
    font-size: 14px !important;
  }
  .wrapper-gallery {
    width: 150%;
    margin-left: 30px !important;
  }
  .fullscreen img {
    width: 100%;
    height: 100%;
    margin-top: 200px;
  }
}
.brand-dropdown {
  display: none !important;
}

.sidenavbar.active {
  top: 166px !important;
}

@media screen and (max-width: 389px) {
  .sidenavbar {
    width: 100px !important;
    top: -188px !important;
  }
}/*# sourceMappingURL=style.css.map */