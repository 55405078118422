@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Kanit:ital,wght@0,200;1,200&family=Lobster&family=Roboto+Condensed:wght@300&family=Roboto:wght@100;300;500&display=swap');

.header-1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;

#cta-links-insta {
    font-size:30px;
  }
}

.header-1 .cta-image-logo img {
  height: 110px;
  width: 110px;
}

.header-1 i {
  font-size: 20px;
  color: black;
  margin: 4px;
}

.header-1 a {
  margin: 4px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
}

.header-1 .cta-icons i {
  padding: 5px;
}

.navbar {
  background-color: blue;
  color: #fff;
  text-align: center;
  height: 60px;
}

.navbar-center {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
}

.menu {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.menu label {
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  cursor: pointer; /* Add cursor pointer to indicate clickability */
}

.menu li {
  position: relative;
  margin: 0 10px;
}

.menu a {
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 10px;
}

/* Remove :hover styles for .product-menu */
.product-menu {
  label {
    text-decoration: none;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    cursor: pointer;
  }
}

/* Adjust styles for the product list based on state */
.product-list {
  display: none;
  position: absolute;
  background-color: #fff;
  color: #fff;
  top: 100%;
  left: 0;
  width: 200px;
  max-width: 800px;
  cursor: pointer;
  border: 1px solid #fff
  ;}

/* Show the product list when .show class is applied */
.product-list.show {
  display: block;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  
}


.product-item {
  text-align: center;
  text-decoration: underline;
  font-weight: 700;

}

.product-item:hover{
  background-color: rgb(207, 205, 205);
} 
.cta-titles {
  color: #000;
}



/* Responsive design */
@media (max-width: 1200px) {
  .navbar-center {
    padding: 10px;
  }
}

@media (max-width: 980px) {
  .menu li {
    margin-right: 10px;
  }
}

@media (max-width: 870px) {
  .menu {
    flex-direction: column;
    align-items: center;
  }

  .menu li {
    margin: 0;
    margin-bottom: 10px;
  }

  .navbar-center {
    padding: 10px;
  }

  .product-list {
    width: 100%;
    position: static;
    display: none;
    max-width: none;
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .menu a {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .menu a {
    font-size: 12px;
  }
}

.cta-links {
  color: #fff !important;
}

/* Your existing CSS styles */

/* Media query for screens less than 720px */
@media screen and (max-width: 870px) {
  /* Hide the menu by default */
  .navbar {
    display: none;
    height: auto;
  }

  /* Style the menu icon */
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  /* Show the menu when it's open */
  .menu-open .navbar {
    display: block;
  }
}

@media screen and (min-width: 870px) {
  .menu-icon {
    display: none;
  }
}

.menu-icon {
  font-size: 2.5rem;
  background-color: #fff;
}
@media screen and (max-width:810px){
  .header-1{
    display: flex;
    flex-wrap: wrap;

    .cta-image-logo{
      img{
        width: 100px;
        height: 100px;

      }
    }
  }
  .header-2{
    .menu {
      gap: 15px;
    }
  }
}
@media screen and (max-width:456px) {
  .header-1{
    a{
      font-size: 12px;
      i{
        font-size: 20px !important;
      }
    }
  }
}
@media screen and(max-width:420px) {
  .cta-links-insta{
      display: none;
  }
  #cta-links-insta{
    display: none;
  }
  .cta-icons{
    display: none;
  }
}