h2 {
  margin-top: 180px;
  margin-left: 80px;
}

.wrapper_1 {
  justify-content: center;
  margin: 80px;
}

.contain-1 {
  max-width: 740px !important;
  height: auto;
}

.cta-image {
  margin-top: 50px;
}
.cta-image img {
  width: 100%;
  height: 600px;
}

@media screen and (max-width: 420px) {
  .wrapper_1 {
    margin: 30px 30px 100px 30px !important;
  }
  .cta-text-about {
    font-size: 22px;
    text-align: center;
    margin-left: 15px !important;
  }
  .cta-taxt-h4-about {
    font-size: 20px !important;
  }
  .cta-taxt-p-about {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 720px) {
  .cta-image img {
    height: auto;
  }
}/*# sourceMappingURL=about.css.map */