.cta-wrapper{

    width: 100%;
    margin: 240px 80px 50px 0px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    

    .cta-container-1{

        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 60px;
        
    
    
      
       .cta-text-1{
        width: 110%;
    
       }
       .cta-img{
        
        width: 90%;
        margin-left: 40px;
        img{
    
            height: 300px;
            width: 400px;
        }
       }
        
        
    }
    
    .cta-container-2{
    
        margin: 80px 30px 80px 30px;
    
        .cta-Accordions{
    
            
            width: 100%;
            
        
            .cta-Accordions-summary{
                height: 60px;
        
                .cta-Accordion-details{
                    height: 80px;
                    background-color: rgb(238, 238, 238);
                }
            }
        
        }
    
    }

}

@media screen and (max-width:1010px) {
    .cta-container-1{
        display: flex;
        flex-wrap: wrap;
        margin: 0px 40px !important;

        .cta-text-1{
            width: 100% !important;
            margin: 20px;
        }
        .cta-img{
            width: 100% !important;
        }
    }

    .cta-img{
        margin-top: 40px;
    }
}
@media screen and(max-width:600px){
    .cta-container-1{
        .cta-text-1{
            h1{
                font-size: 22px;
            }
            p{
                font-size: 14px;
            }
        }
        .cta-img{
            img{
                height: 200px !important;
                width: 300px !important;
            }
        }
    }
}
@media screen and (max-width:421px){

    
    .cta-container-1{
        
        margin: 0px 20px !important;
        
        .cta-text-1{
            margin: 13px !important;
        }
    }
    .cta-wrapper{

        width: 100%;
        margin: 180px 0px 70px 0px !important;

        .cta-img{
            margin-right: 35px !important;
        }

        
        .cta-Accordions-summary-text{
            font-size: 14px !important;
        }
        .cta-Accordion-details-text{
            font-size: 12px !important;
        }
    }
    .cta-container-1{
        margin: 15px;
        .cta-text-1{
            h1{
                font-size: 20px;
            }
            p{
                font-size: 12px;
            }
        }
        
    .cta-container-2{
        margin: 15px;
        .cta-img{
            img{
                height: 240px;
                width: 320;
            }
        }
    }
    }
}
