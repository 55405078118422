.cta-container {
  width: 80%;
}
.cta-container h3 {
  margin: 20px;
}
.cta-container .form-element {
  margin: 30px;
}
.cta-container .form-element .submit-btn {
  padding: 0%;
  background-color: blue;
  color: #fff;
  border-radius: 8px solid blue;
  border: 2px solid blue;
  font-weight: 400;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 700;
}
.cta-container .form-element .submit-btn:hover {
  color: blue;
  background-color: white;
}
.cta-container .form-element input {
  width: 450px;
  height: 40px;
  padding: 12px;
  border-radius: 8px grey;
}
.cta-container .form-element textarea {
  width: 450px;
  height: 250px;
  padding: 12px;
  border-radius: 8px grey;
}

.cta-container h5 {
  margin: 20px;
}
.cta-container h5 i {
  margin: 3px;
}
.cta-container p {
  padding: 20px;
  margin: 20px;
}
.cta-container iframe {
  margin: 30px;
  height: 360px;
  width: 600px;
}

.cta-wrapper-contact {
  display: flex !important;
  align-items: center;
  margin: 180px 0px 40px 0px !important;
  width: 100%;
}

@media screen and (max-width: 1073px) {
  .cta-wrapper-contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .form-element input {
    width: 300px !important;
    height: 40px !important;
    padding: 12px;
    border-radius: 8px grey;
  }
  .form-element textarea {
    width: 300px !important;
    height: 180px !important;
    padding: 12px;
    border-radius: 8px grey;
  }
  iframe {
    margin: 15px;
    height: 260px !important;
    width: 340px !important;
  }
  .cta-contact-location {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 420px) {
  .cta-container .form-element {
    text-align: center;
  }
  .submit-btn {
    margin-top: 0px !important;
  }
  .form-element {
    margin-left: 0px !important;
  }
  .form-element input {
    font-size: 12px !important;
    padding: 4px;
  }
  .form-element textarea {
    font-size: 12px !important;
    padding: 4px;
  }
  .cta-container h3 {
    margin: 2px !important;
    font-size: 18px;
  }
  .cta-container h5 {
    margin: 0px;
    font-size: 18px;
  }
  .form-element input {
    width: 240px !important;
    height: 40px !important;
  }
  .form-element textarea {
    width: 240px !important;
    height: 150px !important;
  }
  iframe {
    margin: 5px;
    height: 230px !important;
    width: 270px !important;
  }
  .cta-container p {
    margin: 0px !important;
    font-size: 12px;
  }
}
@media screen and (max-width: 390px) {
  iframe {
    height: 200px !important;
    width: 250px !important;
  }
}
.cta-contact-location {
  margin-left: 40px;
}/*# sourceMappingURL=contact.css.map */