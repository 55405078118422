
.container-1{
    margin-top: 154px;
}
.carousel-item{

    img{
        width:100%;
        height: 530px !important;
    }
}
.container-2{
    margin: 0px 80px 0px 80px;
    
  h1{
    color: black;
    text-align: center;
    margin: 40px 0px;

    span{
        color: black;
    }
  }
  p{
    text-align: left;
    margin: 25px;
  }


}
.container-3{

    .cta-title-1{
        text-align: center;
        margin: 80px 0px 40px 0px !important;
    }
    .cta-our-brands{
            margin: 0px 80px;  
            
            hr{
                height: 2px;
            }
        .cta-brand{

            display: flex;
            justify-content: center;
            margin-top: 8px;
            width: 100%;

            
            
            
            .cta-texts{
                width: 50% ;
            }

            img{
                height: 400px;
                width: 50%;
                margin: 30px;
                border-radius: 6px;
            }
        }

    }
}
.cta-powered {
    
}
.Container-4{

    margin: 20px;

    .slider {
        height: 200px;
        display: flex;
        justify-items: center;
        align-items: center;
      }
      
      .logo-slider {
        padding: 10px 0px;
        overflow: hidden;
        width: 90%;
        margin: 0 auto;
        position: relative;
        display: flex;
      }
      
      .logo-slider:hover div {
        animation-play-state: paused;
      }
      
      .logo-slider div {
        display: flex;
        position: relative;
        align-items: center;
        animation: marquee 60s linear infinite;
        justify-content: space-around;
      }
      
      .logo-slider div img {
        display: block;
        min-width: 160px;
        aspect-ratio: 1;
        height: 100px;
        margin: 0 2vw;
       
      }
      
      
      
      
      @keyframes marquee {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    

       
}
@media screen and(max-width:790px) {
    .container-3{
        .cta-title-1{
            text-align: center;
            margin: 0px !important;
        }
    }
    .cta-brand{
        display: flex;
        flex-wrap: wrap;

        img{
            height: auto !important;
            width: 100% !important;
        }
        .cta-texts{
            width: 100% !important;
        }
    }

}
@media screen and (max-width:480px){
    .container-3 .cta-title-1{
        font-size: 20px !important;
    }
    .container-3{
        .cta-texts{
            h4{
                font-size: 20px !important;
            }
            p{
                font-size: 12px !important;
            }
        }
    }
    
    .container-2{
        margin: 30px;
        h1{
            font-size: 20px !important;
        }
        p{
            font-size: 12px !important;
        }
    }
    #carouselExampleDark{
        .carousel-inner{
            height: 280px;
        }
    }
    .carousel-item{

        img{
            width:100%;
            height: 300px !important;
        }
    }
}
@media screen and (max-width:380px){
    #carouselExampleDark{
        .carousel-inner{
            height: 200px;
        }
    }
    .container-3{
        .cta-brand{
            h4{
                font-size: 20px;
            }
            p{
                font-size: 12px;
            }
        }
    }
}
@media screen and (max-width:420px) {
    .cta-slide{
        height: 60px !important;
        width: 100px !important;
    }
    .Container-4{
        margin: 20px 30px 80px 30px !important ;
       
        .slider {
            height: 100px !important;
        }}
}