@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Kanit:ital,wght@0,200;1,200&family=Lobster&family=Roboto+Condensed:wght@300&family=Roboto:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');

*{
  margin: 0%;
  padding: 0%;
  text-decoration: none;
  list-style: none;
  font-family: 'Montserrat', sans-serif !important;

}