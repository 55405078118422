.nav-bottom{
    text-decoration: none;
    width: 100%;
    padding: 0px 40px;
}
.footer{
    width: 100%;
    height: auto; 
    background-color: blue;

    hr{
        width: 90%;
        color: #000;
        height: 2px;
    }
}


ul{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items:center ;
    flex-wrap: wrap;

    p{
        color: #fff;
        margin-top: 12px;
        font-weight: 600;
    }
    

    li{
        margin-top: 14px;    
        color: #fff;
        
       
        .cts-links{
            color:#fff;
            text-decoration: none;
            margin: 0px 8px;    
        }

    }
}
.copyright-box{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    P{
        color: white;
        font-size: medium;   
    }
}
.cta-image-1{
    display: flex;
    
    .logo-image-bottom{
        height: 60px;
        width: 120px;
        margin: 4px;
    }
    p{
        margin-top: 20px;
    }
    
}
@media screen and(max-width: 768px){
    .copyright-box{
        display: flex;
        flex-wrap: wrap;
       
    }
    
}
@media  screen and(max-width:464px){
    .copyright-box{
        padding: 4px;
        margin: 4px !important;
        font-size: 14px;
    }
}
.bottom-navbar{
    width: 100%;
    display: none;

    .wrapper-bottom-navbar{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px;    
    }

    .cta-link-bottom-bar{

        
        a{
            color: #000;
            i{
                font-size: 22px;
            }
        }
    }

}

@media screen and (max-width:420px) {
    
    .footer{
        display: none;
    }
    .bottom-navbar{
        display: block;
        position: fixed;
        bottom: 0;
        
        background-color: #fff;
    }

}
